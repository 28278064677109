<template>
  <v-dialog
    :value="true"
    max-width="300"
    persistent
    scrollable
  >
    <v-card >
      <v-toolbar dark color="primary">
        <v-toolbar-title>Person(en) wählen</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="padding: 8px;">
        <v-list v-if="users">
          <v-list-item
            v-for="user in users"
            :key="user.id"
            @click.stop="select(user.id)"
          >
            <v-list-item-avatar>
              <user-avatar :user="user"/>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $root.getUserName(user.id) }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action v-if="multiple">
              <v-checkbox v-model="userIds" :value="user.id" @click="select(user.id)"/>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-else class="text-center my-10">
          <v-progress-circular
            color="primary"
            indeterminate
            size="50"
          />
        </div>
      </v-card-text>
      <v-card-actions v-if="multiple">
        <v-spacer/>
        <v-btn color="primary" text :disabled="userIds.length === 0" :loading="loading" @click="select()">Weiter</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserAvatar from '@/components/base/UserAvatar'

export default {
  name: 'UserPickerDlg',
  components: {
    UserAvatar
  },
  props: {
    multiple: Boolean
  },
  data () {
    return {
      users: null,
      userIds: [],
      loading: false
    }
  },
  methods: {
    select (userId) {
      if (!this.multiple) {
        this.loading = true
        this.$emit('select', [userId])
      } else if (userId) {
        const userIdx = this.userIds.indexOf(userId)
        if (userIdx === -1) {
          this.userIds.push(userId)
        } else {
          this.userIds.splice(userIdx, 1)
        }
      } else {
        this.loading = true
        this.$emit('select', this.userIds)
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.users = Object.keys(this.$root.users)
        .map(userId => ({ id: userId, ...this.$root.users[userId] }))
        .filter(user => user.id !== this.$root.userId && user.email && !user._disabled) // remove self, nologin and disabled
        .sort((a, b) => a.name < b.name ? -1 : 1)
    }, 100)
  }
}
</script>
