<template>
  <v-container>
    <v-list v-if="chats && chats.length > 0">
      <v-list-item
        v-for="item in chats"
        :key="item.id"
        two-line
        class="list-item"
        @click="chat = item"
      >
        <v-list-item-avatar>
          <user-avatar :user="item.name ? { name: item.name } : $root.users[item.userIds.find(userId => userId !== $root.userId)]"/>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-between flex-nowrap">
            <span>{{ getChatName(item) }}</span>
            <v-badge
              v-if="item.unreadCounts[$root.userId]"
              :content="item.unreadCounts[$root.userId]"
              :value="item.unreadCounts[$root.userId]"
              color="primary"
              inline
              class="counter-badge"
            />
          </v-list-item-title>
          <v-list-item-subtitle class="d-flex justify-space-between flex-nowrap">
            <span>{{ item.lastText }}</span>
            <span>{{ item.lc | time }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-dialog
      :value="chat !== null"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card class="d-flex flex-column" style="height: 100%;">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="chat = null">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ chat ? getChatName(chat) : '' }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click.stop="edit">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="chat = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="scrollable" style="flex: 1;">
          <chat-detail
            v-if="chat"
            :chat="chat"
          />
        </div>
      </v-card>
    </v-dialog>

    <fab-btn @click="add"/>

    <user-picker-dlg
      v-if="userPickerDlg"
      multiple
      @select="addUsers($event)"
      @cancel="userPickerDlg = false"
    />

    <v-dialog
      v-model="addChatDlg"
      max-width="500"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Chatgruppe erstellen</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="addChatDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-3">
          <v-text-field v-model="name" label="Name"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="save">Speichern</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editChatDlg"
      scrollable
      max-width="500"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ (chat && chat.userIds.length > 2 ? 'Gruppen-' : '') + 'Chat bearbeiten'}}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="editChatDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-3">
          <v-checkbox v-model="mute" label="Stumm schalten"/>
          <div v-if="chat && chat.userIds.length > 2" class="mt-3">
            <v-text-field v-model="name" label="Name"/>

            <v-subheader class="list-fullwidth">Gruppen-Mitglieder</v-subheader>
            <v-list dense class="list-fullwidth">
              <v-list-item
                v-for="user in users"
                :key="user.id"
              >
                <v-list-item-avatar>
                  <user-avatar :user="user"/>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ user.name }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon v-if="users.length > 1">
                  <v-icon color="error" @click="delUser(user.id)">
                    {{ user.id === $root.userId ? 'mdi-minus-circle' : 'mdi-minus-circle-outline' }}
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <v-btn fab color="primary" small class="mt-2" @click="userPickerDlg = true"><v-icon>mdi-plus</v-icon></v-btn>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text :loading="saving" @click="save">Speichern</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ChatDetail from '@/components/chat/ChatDetail'
import FabBtn from '@/components/base/FabBtn'
import UserAvatar from '@/components/base/UserAvatar'
import UserPickerDlg from '@/components/base/UserPickerDlg'

export default {
  name: 'ChatsView',
  components: {
    ChatDetail,
    FabBtn,
    UserAvatar,
    UserPickerDlg
  },
  props: {
    config: Object,
    itemId: String
  },
  data () {
    return {
      chats: null,
      chat: null,
      loading: false,
      addChatDlg: false,
      editChatDlg: false,
      userPickerDlg: false,
      name: null,
      mute: null,
      userIds: [],
      saving: false
    }
  },
  computed: {
    users () {
      return this.userIds
        .map(userId => ({ id: userId, ...this.$root.users[userId] }))
        .filter(user => !user._disabled) // remove disabled
        .sort((a, b) => a.name < b.name ? -1 : 1)
    }
  },
  methods: {
    subscribe () {
      if (this.unsubscribe) {
        this.unsubscribe()
      }

      this.loading = true
      this.chats = null

      this.unsubscribe = this.$fb.db.collection('chats')
        .where('userIds', 'array-contains', this.$root.userId)
        .orderBy('lc', 'desc')
        .limit(100) // TODO: PAGINATION
        .onSnapshot(snap => {
          const chats = []
          snap.forEach(doc => {
            const chat = {
              id: doc.id,
              ...doc.data()
            }
            if (!chat._deleted) { // TODO: support chat deletion?
              chats.push(chat)
            }
          })
          this.chats = chats
          this.loading = false
        })
    },
    getChatName (chat) {
      return chat.name || this.$root.getUserName(chat.userIds.find(userId => userId !== this.$root.userId))
    },
    openItem (chatId) {
      const chat = this.chats?.find(c => c.id === chatId)
      if (chat) {
        this.chat = chat
      } else {
        this.$fb.db.doc('chats/' + chatId).get()
          .then(doc => {
            const chat = { id: doc.id, ...doc.data() }
            if (chat && !chat._deleted) {
              this.chat = chat
            } else {
              this.$root.snack = {
                text: this.$t('item_deleted',),
                color: 'error'
              }
            }
          })
      }
    },
    add () {
      this.name = null
      this.mute = null
      this.userIds = []
      this.userPickerDlg = true
    },
    edit () {
      this.name = this.chat.name || ''
      this.mute = this.chat.mute?.includes(this.$root.userId)
      this.userIds = [...this.chat.userIds]
      this.editChatDlg = true
    },
    async addUsers (userIds) {
      userIds.forEach(userId => {
        if (!this.chat?.userIds.includes(userId)) {
          this.userIds.push(userId)
        }
      })
      // on add..
      if (!this.chat) {
        if (this.userIds.length === 1) {
          await this.save()
          this.userPickerDlg = false
        } else {
          this.userPickerDlg = false
          this.addChatDlg = true
        }
      } else {
        this.userPickerDlg = false
      }
    },
    delUser (userId) {
      this.userIds = this.userIds.filter(uid => uid !== userId)
    },
    async save () {
      this.saving = true
      const saveChatFunc = this.$fb.fn.httpsCallable('saveChat')
      const response = await saveChatFunc({
        id: this.chat?.id,
        viewId: this.config.id,
        userIds: this.userIds,
        name: this.name,
        mute: this.mute
      })
      if (response.data.ok) {
        this.chat = response.data.chat
      }
      this.userPickerDlg = false
      this.editChatDlg = false
      this.addChatDlg = false
      this.saving = false
    },
    back () {
      if (this.userPickerDlg) {
        this.userPickerDlg = false
        return true
      } if (this.addChatDlg) {
        this.addChatDlg = false
        return true
      } if (this.editPostDlg) {
        this.editPostDlg = false
        return true
      } else if (this.chat) {
        this.chat = null
        return true
      }
    }
  },
  watch: {
    itemId: {
      handler (itemId) {
        if (itemId) {
          console.log('openItem')
          this.openItem(itemId)
        }
      },
      immediate: true
    },
    chat (chat) {
      if (chat === null) {
        this.$emit('update:itemId', null)
      }
    }
  },
  created () {
    this.subscribe()
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>

<style scoped>
.list-item >>> .v-list-item__subtitle {
  line-height: 1.3;
}
.list-fullwidth {
  padding: 0;
}
.list-fullwidth >>> .v-list-item {
  padding: 0;
}
.counter-badge {
  margin-top: 0;
  margin-right: 3px;
}
</style>
