<template>
  <div class="d-flex flex-column" style="height: 100%">
    <div ref="messages" class="scrollable" style="flex: 1">
      <v-container>
        <div v-if="messages" class="mb-4 mt-1 text-center">
          <v-btn v-if="hasMore" color="primary" rounded small :loading="loading" @click="loadMore">{{ $t('show_more') }}</v-btn>
        </div>

        <v-sheet
          v-for="message in messages"
          :key="message.id"
          :color="(message.mine ? 'primary' : 'grey') + ' lighten-4'"
          rounded
          class="pre-line pa-2 mb-3"
          :class="message.mine ? 'ml-15' : 'mr-15'"
        >
          <div class="d-flex align-center">
            <span class="text-subtitle-2">{{ $root.getUserName(message.userId) }}</span>
            <v-spacer></v-spacer>
            <span class="pl-3 pr-1 caption">{{ message.ts | time }}</span>
            <v-icon v-if="message.mine" :color="message.read ? 'blue' : 'grey'" small>{{ message.read ? 'mdi-check' : 'mdi-circle-small' }}</v-icon>
            <!-- <v-icon v-if="isMessageEditable" small class="pl-3" @click="deleteCommentId = message.id">mdi-delete</v-icon> -->
          </div>
          <img-cmp v-if="message.img" :img="message.img" class="mb-1"/>
          <div class="text-body-2">{{ message.text }}</div>
          <files-list v-if="message.files && message.files.length > 0" :files="message.files" class="mt-1"/>
        </v-sheet>
      </v-container>
    </div>

    <message-editor :submit="submit" media/>

    <confirm-dlg
      :value="!!deleteMessageId"
      text="Kommentar löschen?"
      @ok="deleteMessage"
      @input="deleteMessageId = null"
    />
  </div>
</template>

<script>
import FilesList from '@/components/base/FilesList'
import ImgCmp from '@/components/base/ImgCmp'
import MessageEditor from '@/components/base/MessageEditor'
import ConfirmDlg from '@/components/base/ConfirmDlg'

const LIMIT = 25

export default {
  name: 'ChatDetail',
  components: { FilesList, ImgCmp, MessageEditor, ConfirmDlg },
  props: {
    chat: Object
  },
  data () {
    return {
      messages: null,
      limit: LIMIT,
      loading: false,
      scrollDown: true,
      hasMore: true,
      deleteMessageId: null
    }
  },
  methods: {
    subscribe () {
      if (this.unsubscribe) {
        this.unsubscribe()
      }

      this.loading = true
      this.lastScrollHeight = this.$refs.messages.scrollHeight
      this.unsubscribe = this.$fb.db.collection('chats/' + this.chat.id + '/messages')
        .orderBy('ts', 'desc')
        .limit(this.limit + 1) // TODO maybe only "patch" messages[]: https://firebase.google.com/docs/firestore/query-data/listen#web-version-8_3
        .onSnapshot(snap => {
          const messages = []
          for (let i = 0; i < Math.min(snap.size, this.limit); i++) {
            const doc = snap.docs[i]
            const message = { id: doc.id, ...doc.data() }
            if (!message._deleted) {
              message.mine = message.userId === this.$root.userId
              messages.push(message)
            }
          }
          this.messages = messages.reverse()
          this.hasMore = snap.size > this.limit
          this.loading = false

          // scroll / reposition
          const el = this.$refs.messages
          this.$nextTick(() => {
            el.scrollTop = el.scrollHeight - (this.scrollDown ? el.clientHeight : this.lastScrollHeight)

            // mark as read
            const readMessageIds = this.messages
              .filter(m => m.userId !== this.$root.userId && !m.readBy[this.$root.userId])
              .map(m => m.id)
            if (readMessageIds.length > 0) {
              const readMessagesFunc = this.$fb.fn.httpsCallable('readMessages')
              readMessagesFunc({ chatId: this.chat.id, messageIds: readMessageIds })
            }

            this.scrollDown = true
          })
        })
    },
    isMessageEditable (message) {
      return message && (message.userId === this.$root.userId || this.$root.isAdmin)
    },
    loadMore () {
      this.limit += LIMIT
      this.scrollDown = false
      this.subscribe()
    },
    async submit ({ text, img, files }) {
      const sendMessageFunc = this.$fb.fn.httpsCallable('sendMessage')
      const response = await sendMessageFunc({
        chatId: this.chat.id,
        text,
        img,
        files
      })
      return response.data?.ok
    },
    async deleteMessage () {
      const deleteMessageFunc = this.$fb.fn.httpsCallable('deleteMessage')
      const response = await deleteMessageFunc({ id: this.deleteMessageId, chatId: this.chat.id })
      if (response.data.ok) {
        this.$emit('delete')
      }
      this.deleteMessageId = null
    }
  },
  mounted () {
    this.subscribe()
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>
